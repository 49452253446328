import { createPopper, Instance } from '@popperjs/core';

const OFFSET_MODIFIER = {
    name: 'offset',
    options: {
        offset: [0, 8],
    }
};

export default class Tooltip extends HTMLElement {
    _popperInstance: Instance;

    constructor() {
        super();

        this._popperInstance = null;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.innerHTML = this.render();
    }

    static get observedAttributes() : string[] {
        return ['for', 'disabletext', 'theme', 'style'];
    }

    attributeChangedCallback(attrName: string, oldVal: string, newVal: string): void {
        if (attrName === 'for' && newVal && this.textContent) {

            // Select the sibling using the container of the tooltip
            const icon = this.parentNode.querySelector(newVal) as HTMLElement;
            if (icon) {
                this.init(icon, this.textContent);
            } else {
                console.warn(`Can't find node using query ${newVal} for tooltip with text ${this.textContent}`);
            }
        } else if (attrName === 'theme' && newVal) {
            const tooltip = this.shadowRoot.querySelector('.tooltip');
            tooltip.classList[newVal === 'light' ? 'add' : 'remove']('light-theme');
        }
    }

    init(tooltipTriggerer: HTMLElement, message: string): void {
        const tooltip = this.shadowRoot.querySelector('.tooltip') as HTMLElement;
        const disableText = this.getAttribute('disabletext') ? true : false;
        if (!disableText) {
            tooltip.querySelector('span').innerText = message;
        }

        this._popperInstance = createPopper(tooltipTriggerer, tooltip, {
            placement: 'top',
            modifiers: [OFFSET_MODIFIER]
        });

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach(event => {
            tooltipTriggerer.addEventListener(event, this.show);
        });

        hideEvents.forEach(event => {
            tooltipTriggerer.addEventListener(event, this.hide);
        });
    }

    show = () => {
        this.shadowRoot
            .querySelector('.tooltip')
            .setAttribute('data-show', '');

        this._popperInstance.setOptions({
            modifiers: [{ name: 'eventListeners', enabled: true }, OFFSET_MODIFIER],
        });

        this._popperInstance
            .update();
    };

    hide = () => {
        this.shadowRoot
            .querySelector('.tooltip')
            .removeAttribute('data-show');

        this._popperInstance.setOptions({
            modifiers: [{ name: 'eventListeners', enabled: false }, OFFSET_MODIFIER],
        });
    };

    render = () => `
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
        <style>
        .tooltip {
			background: #333;
			color: white;
			padding: 4px 8px;
			font-size: 13px;
			border-radius: 4px;
            max-width: 600px;
            display: none;
            z-index: 2;
		}

        .tooltip.light-theme{
            background: #fff !important;
            color: #2b2f43 !important;
            border: 1px solid #CDD5E4;
            box-shadow: 0px 8px 35px rgb(0 0 0 / 10%);
        }

        .tooltip[data-show] {
			display: block;
		}
		.tooltip-arrow,
		.tooltip-arrow:before {
			position: absolute;
			width: 8px;
			height: 8px;
			background: inherit;
		}
		.tooltip-arrow {
			visibility: hidden;
		}
		.tooltip-arrow:before {
			visibility: visible;
			content: '';
			transform: rotate(45deg);
		}
		.tooltip[data-popper-placement^='top'] > .tooltip-arrow {
			bottom: -4px;
		}

        .tooltip[data-popper-placement^='bottom'] > .tooltip-arrow  {
            top: -4px;
        }

          .tooltip[data-popper-placement^='left'] > .tooltip-arrow  {
            right: -4px;
          }

          .tooltip[data-popper-placement^='right'] > .tooltip-arrow  {
            left: -4px;
          }
        </style>
        <div class="tooltip" role="tooltip">
            <span></span>
            <slot name="data"></slot>
            <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
    `;
}

customElements.define('fl-tooltip', Tooltip);
